import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../context';
import './Login.css'
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import settings from './../../settings'

export default function Login(props) {
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const {authorized, setAuthorized} = useContext(AuthContext);
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	
	const SendLogin = async () => {
		//const response = await axios.post('http://localhost:82/api/login');
		const {data} = await axios.post(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/auth/by-login', {
			login: login,
			password: password,
			}, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			}
		});
		console.log(data);
		if(data.token !== undefined){
			setCookie('token', data.token);
			setAuthorized(true);
		}else{
			setAuthorized(false);
			removeCookie('token');
			console.error(data.resText);
		}
	}
	return (
	<div id='login-holder'>
			<div id='login-panel'>
				<Input className="form-control mb-1" type="text" value={login} onChange={event => setLogin(event.target.value)} />
				<Input className="form-control mb-1" type="password" value={password} onChange={event => setPassword(event.target.value)} />
				<Button className="btn btn-primary mt-2" onClick={SendLogin}>Войти</Button>
			</div>
	</div>
  )
}
