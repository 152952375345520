import React, { useEffect, useState } from 'react'
import settings from '../../../../settings';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { WorkTypes } from './Tabs/WorkTypes/WorkTypes';
import './Tasks.css'
import 'react-tabs/style/react-tabs.css';
import { Tooltip } from 'react-tooltip';


export const Tasks = (props) => {
	const [typesList, setTypesList] = useState([]);
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const [tabIndex, setTabIndex] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		GetTypes();
	}, [])

	const GetTypes = async () => {
		const {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/types?token=' + cookies.token + '&apiVersion=2.0');
		console.log(data);
		setTypesList(data.items);
		
	}
	const tabClassName = ['tab-content'];
	return (
			<Tabs id='task-tabs' className={[loading ? 'blur' : '']} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
				<TabList className={['tabs-list']}>
					<Tab><span className='tab-title'>Виды работ</span></Tab>
					<Tab><span className='tab-title'>Этапы</span></Tab>
					<Tab><span className='tab-title'>Приоритеты</span></Tab>
					<Tab><span className='tab-title'>Настраиваемые поля</span></Tab>
					<Tab><span className='tab-title'>Ярлыки для файлов</span></Tab>
					<Tab><span className='tab-title'>Роли и права</span></Tab>
					<Tab><span className='tab-title'>Плагины и Web-хуки</span></Tab>
				</TabList>

				<TabPanel className={tabClassName}>
					<WorkTypes typesList={typesList} />
				</TabPanel>
				<TabPanel className={tabClassName}>
					Этапы
				</TabPanel>
				<TabPanel className={tabClassName}>
					Приоритеты
				</TabPanel>
				<TabPanel className={tabClassName}>
					Настраиваемые поля
				</TabPanel>
				<TabPanel className={tabClassName}>
					Ярлыки для файлов
				</TabPanel>
				<TabPanel className={tabClassName}>
					Роли и права
				</TabPanel>
				<TabPanel className={tabClassName}>
					Плагины и Web-хуки
				</TabPanel>
			</Tabs>

	)
}
