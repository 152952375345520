import React from 'react'
import './Button.css'

export default function Button(props) {
  return (
	<button
	id={props.id}
	className={'cwf-button1' + (props.className != undefined ? ' ' + props.className : '')}
	onClick={props.onClick}
	>
		{props.children}
	</button>
  )
}
