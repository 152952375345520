import React from 'react'
import './WorkType.css'

export const WorkType = (props) => {
	function dhm(t){
		var cd = 24 * 60 * 60,
			ch = 60 * 60,
			d = Math.floor(t / cd),
			h = Math.floor( (t - d * cd) / ch),
			m = Math.round( (t - d * cd - h * ch) / 60),
			pad = function(n){ return n < 10 ? '0' + n : n; };
	  if( m === 60 ){
		h++;
		m = 0;
	  }
	  if( h === 24 ){
		d++;
		h = 0;
	  }
	  return [d, h, m];
	}

	return (
		<div id='work-type'>
			<div>
				<label for="name" class="form-label">Название:</label>
				<input type="text" class="form-control" id="name" placeholder="Название" name="name" value={props.workType.name}/>
			</div>
			<div>
				<label for="cluster" class="form-label">Кластер:</label>
				<input type="text" class="form-control" id="cluster" placeholder="Кластер" name="cluster" value={(props.workType.cluster != null ? props.workType.cluster.title : 'Не указан')} disabled/>
			</div>
			<div>
				<label for="group_id" class="form-label">Группа видов работ:</label>
				<select name="" id="" className='form-select' value={(props.workType.group_id != null ? props.workType.group_id : '')}>
					<option value=""></option>
					{Object.getOwnPropertyNames(props.workTypesGrops).map((groupId) => <option value={groupId}>{props.workTypesGrops[groupId]}</option>)}
				</select>
			</div>
			<div>
				<label for="period-days" class="form-label">Срок выполнения:</label>
				<div id='period'>
					<div class="input-group">
						<span class="input-group-text">дд</span>
						<input type="number" class="form-control" id="" placeholder="0" name="period-days" value={dhm(props.workType.period_of_review_in_sec)[0]}/>
					</div>
					<div class="input-group">
						<span class="input-group-text">чч</span>
						<input type="number" class="form-control" id="" placeholder="0" name="period-hours" value={dhm(props.workType.period_of_review_in_sec)[1]}/>
					</div>
					<div class="input-group">
						<span class="input-group-text">мм</span>
						<input type="number" class="form-control" id="" placeholder="0" name="period-minutes" value={dhm(props.workType.period_of_review_in_sec)[2]}/>
					</div>
				</div>
			</div>
			<div>
				<label for="deadline_type" class="form-label">Момент установки срока выполнения:</label>
				<select name="" id="" className='form-select' value={(props.workType.deadline_type != null ? props.workType.deadline_type : '')}>
					<option value=""></option>
					{Object.getOwnPropertyNames(props.startPoint).map((startPointId) => <option value={startPointId}>{props.startPoint[startPointId]}</option>)}
				</select>

			</div>
		</div>
	)
}
