import React from 'react'
import './Input.css'

export default function Input(props) {
  return (
	<input
		id={props.id}
		className={'cwf-input1' + (props.className != undefined ? ' ' + props.className : '')}
		value={props.value}
		type={props.type}
		onChange={props.onChange}
	/>
  )
}
