import React from 'react'
import settings from '../../settings';
import { useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import './FieldsInfo.css'
import toast, { Toaster } from 'react-hot-toast';

export const FieldsInfo = () => {
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const [fieldsList, setFieldsList] = useState([]);
	const [filteredFieldsList, setFilteredFieldsList] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		GetFields();
	}, [])

	useEffect(() => {
		setFilteredFieldsList(fieldsList.filter(function(currentValue, index, arr){
			if(searchValue == '' || currentValue.id == searchValue || (currentValue.name !== undefined && currentValue.name.toLowerCase().includes(searchValue.toLowerCase())) || (currentValue.translit != undefined && currentValue.translit.toLowerCase().includes(searchValue.toLowerCase()))){
				return true;
			}else{
				return false;
			}
		}));
	}, [fieldsList, searchValue])

	const GetFields = async () => {
		const {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/fields?token=' + cookies.token + '&apiVersion=2.0');
		console.log(data);
		setFieldsList(data.items);
		
	}
	const CopyToClipboard = (text) => {
		//event.target.select();
		//event.target.setSelectionRange(0, 99999); // For mobile devices

		// Copy the text inside the text field
		//console.log(event.target)
		//navigator.clipboard.writeText(event.target.innerText);
		navigator.clipboard.writeText(text);

		if (window.getSelection) {window.getSelection().removeAllRanges();}
		else if (document.selection) {document.selection.empty();}

		toast.success(<div><div>Скопировано в буфер обмена</div><div style={{ fontSize: 20, fontWeight: 'bold' }}>{text}</div></div>, { className: 'notif' });

	}

	return (
		<div className='fields-wrapper'>
			<div>Fields</div>
			<div><input type="number" name="" id="" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} /></div>
			{filteredFieldsList.map((field) => 
				<div key={field.id} className='field-row'>
					<div className='field-prop' onClick={e => CopyToClipboard(e.target.innerText)}>#{field.id}</div>
					<div className='field-prop' onClick={e => CopyToClipboard(e.target.innerText)}>{field.name}</div>
					<div>
						<div className='field-prop' onClick={e => CopyToClipboard(e.target.innerText)}>{field.translit}</div>
						<div className='field-prop' onClick={e => CopyToClipboard("varName = model.get('" + field.translit + "') #" + field.id + "\n")}>varName = model.get('{field.translit}') #{field.id}</div>
						<div className='field-prop' onClick={e => CopyToClipboard('            "' + field.translit + '": { "field_id": ' + field.id + ', "value" : varName },' + "\n")}>wwwwww</div>
						<div className='field-prop' onClick={e => CopyToClipboard("				task.custom_fields -> '" + field.translit + "' ->> 'value' as varName," + "\n")}>Report</div>
					</div>
					{(field.possible_values != null ?
					(field.possible_values.split('\n').map((possibleValue) => <div className='field-prop' onClick={e => CopyToClipboard(e.target.innerText)}>{possibleValue}</div>))
					:
					<div></div>)}
				</div>
			)}
			<Toaster />
		</div>
	)
}
