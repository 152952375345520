import React, { useEffect, useState } from 'react'
import settings from '../../../../settings';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export const Main = (props) => {
	const [typesList, setTypesList] = useState([]);
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);


	return (
		<div>
			<div>Тут будет управление</div>

		</div>
	)
}
