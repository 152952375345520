import { useEffect, useState } from 'react';
import './App.css';
import AppContent from './AppContent';
import Login from './Pages/Login/Login';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { AuthContext } from './context';
import settings from './settings'

function App() {
	const [authorized, setAuthorized] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

	useEffect(() => {
		Ping();
	}, [])
	const Ping = async () => {
		try{
			const {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/licenses/info?token=' + cookies.token + '&apiVersion=2.0');

			if(data.license_key){
				console.log('Сессия есть');
				setAuthorized(true);
			}else{
				console.log(data.error_description);
				setAuthorized(false);
			}
			setLoaded(true);
	
		}catch(e){
			console.error(e);
		}
	}

	return (
		<AuthContext.Provider value={{
			authorized: authorized,
			setAuthorized: setAuthorized,
		}}>
				{loaded ?
					authorized ?
					<AppContent/> :
					<Login/>:
				<div></div>
				}
				
		</AuthContext.Provider>
	);
}

export default App;
