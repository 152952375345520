import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import settings from '../../settings';
import './Manage.css'
import { FaListCheck } from "react-icons/fa6";
import { Tasks } from '../../components/manage/sections/Tasks/Tasks';
import { Main } from '../../components/manage/sections/Main/Main';
import { MdManageAccounts } from "react-icons/md";
import { LuLayers } from "react-icons/lu";
import { Layers } from '../../components/manage/sections/Layers/Layers';

export default function Manage() {
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const {authorized, setAuthorized} = useContext(AuthContext);
	const [typesList, setTypesList] = useState([]);
	const [currentSection, setCurrentSection] = useState('Tasks');

	useEffect(() => {
		GetLayers();
	}, [])
	useEffect(() => {
		console.log(currentSection);
	}, [currentSection])
	useEffect(() => {
		//setCurrentSection(<Tasks typesList={typesList}/>);
	}, [typesList])

	const GetLayers = async () => {
		const {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/types?token=' + cookies.token + '&apiVersion=2.0');
		//console.log(data);
		setTypesList(data.items);
		
	}
	const sections = {
		'Main': <Main/>,
		'Tasks': <Tasks/>,
		'Layers': <Layers/>,
	}
	const ManageSection = (sectionName) => {
		setCurrentSection(sectionName);
	}

	return (
		<div className='frame'>
			<div id='manage-panel' className='frame'>
			
				<div className={'manage-section-button' + (currentSection == 'Main' ? ' active' : '')} onClick={() => ManageSection('Main')}>
					<MdManageAccounts />
					<div>Управление</div>
				</div>
				<div className={'manage-section-button' + (currentSection == 'Tasks' ? ' active' : '')} onClick={() => ManageSection('Tasks')}>
					<FaListCheck />
					<div>Задания</div>
				</div>
				<div className={'manage-section-button' + (currentSection == 'Layers' ? ' active' : '')} onClick={() => ManageSection('Layers')}>
					<LuLayers />
					<div>Слои и таблицы</div>
				</div>
			</div>
			<div id='manage-comtent' className='frame'>
				{sections[currentSection]}
			</div>




		</div>
	)
}
