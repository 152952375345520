import React, { useContext } from 'react'
import './NavBar.css'
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../context';
import NavButtonsPanel from '../NavButtonsPanel/NavButtonsPanel';

export default function NavBar() {
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const {authorized, setAuthorized} = useContext(AuthContext);

  return (
	<nav id='nav-bar'>
		<NavButtonsPanel/>
	</nav>
  )
}
