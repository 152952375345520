import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainContent from './components/MainContent/MainContent'
import NavBar from './components/NavBar/NavBar'
import Manage from './Pages/Manage/Manage'
import Login from './Pages/Login/Login'
import { FieldsInfo } from './Pages/FieldsInfo/FieldsInfo'

export default function AppContent() {



  return (
	<BrowserRouter basename="/">
	<NavBar/>
	<MainContent>
		<Routes>
			<Route
				element={<Manage />}
				path="/"
				errorElement={<div>Error</div>}
			/>
			<Route
				element={<FieldsInfo />}
				path="/fields-info"
				errorElement={<div>Error</div>}
			/>
			<Route
				element={<Login />}
				path="/login"
				errorElement={<div>Error</div>}
			/>
		</Routes>
	</MainContent>
</BrowserRouter>

  )
}
