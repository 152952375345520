import React, { useContext } from 'react'
import NavButton from '../NavButton/NavButton'
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../context';
import axios from 'axios';
import './NavButtonsPanel.css'
import settings from '../../settings';


export default function NavButtonsPanel() {
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const {authorized, setAuthorized} = useContext(AuthContext);

	const Logout = async () => {
		console.log('Logout');
		const url = encodeURI(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/auth/tokens/' + cookies.token + '?token=' + cookies.token);
		console.log('url = ', url);
		console.log('token = ', cookies.token);
		const {data} = await axios.delete(url);
		console.error(data);
		if(data.error !== undefined){
			console.error(data.error);
		}

		setAuthorized(false);
		removeCookie('token');

	}

  return (
	<div id='nav-buttons-panel' className='ms-5'>
		<NavButton href="/">Управление</NavButton>
		<NavButton href="/fields-info">Доп.поля</NavButton>
		<NavButton onclick={Logout}>Выйти</NavButton>

	</div>
  )
}
