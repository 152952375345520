import React, { useEffect, useState } from 'react'
import './WorkTypes.css'
import { TbClockCheck } from "react-icons/tb";
import axios from 'axios';
import settings from '../../../../../../settings';
import { useCookies } from 'react-cookie';
import { MdOutlineLockClock } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import { WorkType } from './WorkType/WorkType';

export const WorkTypes = (props) => {
	const [workTypesGrops, setWorkTypesGrops] = useState({});
	const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
	const [currentWorkType, setCurrentWorkType] = useState(null);

	const startPoint = {
		'AT_CREATE': 'При создании задания',
		'AT_UPDATE': 'При назначении задания',
	}
	const GetTypesGroups = async () => {
		const {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/types/groups/list?token=' + cookies.token + '&apiVersion=2.0');
		console.log(data);
		let groups = {};
		for(let groupIndex in data.items){
			groups[data.items[groupIndex].id] = data.items[groupIndex].name;
		}
		setWorkTypesGrops(groups);
	}

	useEffect(() => {
		GetTypesGroups();
	}, [])

	function createLabel(number, titles) {
		const cases = [2, 0, 1, 1, 1, 2];
		return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
	}
	const dayVarint = ['день', 'дня', 'дней'];
	
	const OpenWorkType = async (event) => {
		let {data} = await axios.get(settings.protocol + '://' + settings.host + ':' + settings.port + '/rest/types/' + event.currentTarget.getAttribute("data-id") + '?token=' + cookies.token + '&apiVersion=2.0');
		console.log(data);
		console.log(data);
		if(data.period_of_review_in_sec != null){
			
			data.period_of_review = data.period_of_review_in_sec / 86400;
		}
		/*
		let groups = {};
		for(let groupIndex in data.items){
			groups[data.items[groupIndex].id] = data.items[groupIndex].name;
		}
		//*/
		setCurrentWorkType(data);
	}
	function dhm(t){
		var cd = 24 * 60 * 60,
			ch = 60 * 60,
			d = Math.floor(t / cd),
			h = Math.floor( (t - d * cd) / ch),
			m = Math.round( (t - d * cd - h * ch) / 60),
			pad = function(n){ return n < 10 ? '0' + n : n; };
	  if( m === 60 ){
		h++;
		m = 0;
	  }
	  if( h === 24 ){
		d++;
		h = 0;
	  }
	  return [d, h, m];
	}

	return (
		<div className='tab-content-wrapper'>
			<div id='work-types-list' className='frame1'>
				{props.typesList.map((item, i) => {
				return(
					<div key={item.id} className='item' onClick={OpenWorkType} data-id={item.id}>
						<div className='icon'>
							<div className='work-type-id'>#{item.id}</div>
							<div><img src={'https://stroilider-rostov.activemap.ru/department_files/department_icons/' + item.icon} alt="" /></div>
						</div>
						<div className='info'>
							<div className='top'>
								<div className='name'>{item.name}</div>
							</div>
							<hr className='m-0'/>
							<div className='bottom'>
								<div>
									<div className='group'><span className='label'>Группа: </span> <span className='group-title'>{(item.group_id != null ? workTypesGrops[item.group_id] : 'Без группы')}</span></div>
								</div>
								<div className='extra-info'>
									<div
										className='period'
										title="Срок выполнения"
									><span className='icon-label'><TbClockCheck /> </span>{(item.period_of_review_in_sec != null ? dhm(item.period_of_review_in_sec)[0] + ' ' + createLabel(dhm(item.period_of_review_in_sec)[0], dayVarint) : '')} </div>
									<div
									className='start-point'
									title="Момент установки срока выполнения"
									><span className='icon-label'><MdOutlineLockClock /> </span>{startPoint[item.deadline_type]}</div>
									
								</div>
							</div>
						</div>
						<div className='cluster'><span className='label'>Кластер</span><span className='cluster-title'>{(item.cluster instanceof Object ? item.cluster.title : 'Нет')}</span></div>

					</div>
				
				)
				})}
			</div>
			<div id='work-types-view' className='frame1'>
				{(currentWorkType != null ? <WorkType workType={currentWorkType} workTypesGrops={workTypesGrops} startPoint={startPoint}/> : <div></div>)}
			</div>
		</div>
	)
}
