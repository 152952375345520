import React from 'react'
import './NavButton.css'
import { Link } from 'react-router-dom'


export default function NavButton(props) {
  return (
	<Link className='nav-button' onClick={props.onclick} to={props.href}>{props.children}</Link>
  )
}
